/* iCheck plugin Flat skin, green
----------------------------------- */
.icheckbox_flat-green,
.iradio_flat-green {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 20px;
    height: 20px;
    background: url(green.png) no-repeat;
    border: none;
    cursor: pointer;
}

.icheckbox_flat-green {
    background-position: 0 0;
}
    .icheckbox_flat-green.checked {
        background-position: -22px 0;
    }
    .icheckbox_flat-green.disabled {
        background-position: -44px 0;
        cursor: default;
    }
    .icheckbox_flat-green.checked.disabled {
        background-position: -66px 0;
    }

.iradio_flat-green {
    background-position: -88px 0;
}
    .iradio_flat-green.checked {
        background-position: -110px 0;
    }
    .iradio_flat-green.disabled {
        background-position: -132px 0;
        cursor: default;
    }
    .iradio_flat-green.checked.disabled {
        background-position: -154px 0;
    }

/* Retina support */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
       only screen and (-moz-min-device-pixel-ratio: 1.5),
       only screen and (-o-min-device-pixel-ratio: 3/2),
       only screen and (min-device-pixel-ratio: 1.5) {
    .icheckbox_flat-green,
    .iradio_flat-green {
        background-image: url(green@2x.png);
        -webkit-background-size: 176px 22px;
        background-size: 176px 22px;
    }
}